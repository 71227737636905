@import "../../assets/styles/config.scss";

$spacing-overlap: 100px;
$header-small-width: 567px;

.form-header {
	.container-small {
		@include for-phone-only {
			padding: 0 !important;
			width: 100%;
		}
	}
}

.header {
	min-height: 80vh;
	padding: 2em 0;
	position: relative;
	display: flex;
	flex-direction: column;

	@include for-phone-only {
		padding-top: $nav-height;
	}

	&_has-card {
		margin-bottom: $spacing-overlap;
		padding-bottom: 0;
	}

	.container {
		z-index: 5;
	}

	&-big-g {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		overflow: hidden;
		@include for-phone-only {
			top: auto;
			bottom: 0;
			right: 0;
		}
		svg {
			transform: translate(40px, -40px);
			width: 400px;
			height: 400px;
			opacity: 0.5;

			@include for-phone-only {
				width: 300px;
				transform: translate(40px, 40px);
			}
		}
	}

	&-card {
		z-index: 2;
		display: flex;
		margin-top: 60px;
		margin-bottom: -$spacing-overlap;
		justify-content: center;
		@include for-phone-only {
			margin-top: 30px;
		}
		.card {
			max-width: $header-small-width;
		}
		.dropdown {
			margin-bottom: 15px;
		}
		.button {
			margin-top: 30px;
			width: 100%;
		}
	}

	&_primary {
		background: $cs-dark-blue;
		color: #fff;
	}

	&_full {
		flex: 1;
		display: flex;
		flex-direction: column;
		.container,
		.container-lg {
			flex: 1;
			@include full-height();
		}
		.header-content {
			max-width: 100%;
			padding: 0;
			width: 100%;
			flex: 1;
			p {
				text-align: left;
			}
		}
	}

	&_company,
	&_student {
		min-height: 50vh;
		.header-content {
			padding: 0;
			max-width: $header-small-width;
			text-align: left;
			width: 100%;
		}
	}

	&_company {
		color: #fff;
		background: $cs-base;
		.header-background {
			color: #fff;
			background: $cs-base;
		}
	}

	&_student,
	&_white {
		background: $cc-base;
		color: $cc-dark-blue;
		.header-background {
			background: $cc-base;
			color: $cc-dark-blue;
		}
	}

	.container-small {
		@include full-height();
	}
	&-content {
		padding: 80px 0;
		z-index: 5;
		margin: 0 auto;
		@include full-height();

		.floating-block {
			position: absolute;
			left: 0;
			@include full-center();
			strong {
				text-align: center;
			}
			.buttons {
				margin-top: 10px;
			}
		}
		.buttons {
			margin-top: 40px;
		}
	}
}

.header_small {
	padding: 0 !important;
	background: #fff;

	.header-background,
	.header-content {
		@include for-phone-only {
			padding-top: $nav-height !important;
		}
	}

	.header-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 500px;
		padding: 2em 0;
		padding-top: 140px;
	}
	.header-content {
		padding: 2em 0;
		padding-top: 140px;
		width: 100%;
		display: block;
		max-width: 100%;
		z-index: 5;
		justify-content: center;
		h2 {
			margin-bottom: 50px;
		}
		p,
		strong,
		b {
			text-align: left;
		}
		strong {
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 30px;
		}
	}
}

.header_xsmall {
	padding: 0 !important;
	min-height: 300px !important;
	.header-big-g {
		svg {
			height: 300px;
		}
	}
	.header-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 500px;
		padding: 2em 0;
		padding-top: 140px;
	}
	.header-content {
		padding: 140px 0 2em;
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 100%;
		z-index: 5;
		h1 {
			margin: 0 0 20px;
		}
		p {
			text-align: left;
		}
	}
}
