@import "../../assets/styles/config.scss";

$review-padding: 20px;

.review {
	display: flex;
	align-items: center;
	.card-content {
		display: flex;
	}
	&-head {
		display: flex;
		align-items: center;
		padding: $review-padding;
		.avatar {
			img {
				margin-bottom: 0;
			}
			.icon {
				border: 1px solid $color-valid;
			}
		}
	}

	.name {
		margin: 0 !important;
	}

	.review-task {
		margin: 5px 0;
		opacity: 0.5;
	}

	&-content {
		padding: $review-padding $review-padding $review-padding 0;
		flex: 1;
		p {
			text-align: left !important;
			margin: 0;
		}
		.name {
			font-weight: bold;
			margin-bottom: 10px;
			color: $color-purple;
			display: block;
			text-decoration: none;
		}
	}
}

.review-resume {
	flex-direction: column;
	align-items: flex-start;
	border-bottom: 1px solid $color-gray;
	&:last-child {
		border-bottom-color: transparent;
	}
	.avatar {
		@include for-phone-only {
			margin-bottom: 20px;
		}
		img {
			margin-bottom: 0;
		}
		.icon {
			border: 1px solid $color-valid;
		}
	}
	.review-info {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		width: 100%;
		@include for-phone-only {
			flex-direction: column;
		}
		.info {
			flex: 1;
			margin-left: 20px;
			@include for-phone-only {
				margin: 0;
			}
			p {
				text-align: left;
				@include for-phone-only {
					text-align: center;
				}
			}
		}
		.name {
			margin-bottom: 10px;
		}
		.company {
			margin: 0;
		}
		.rating-stars {
			margin: 10px 0 0;
		}
	}
	.review-description {
		p {
			text-align: left;
			@include for-phone-only {
				text-align: center;
			}
		}
	}
}

.review-default {
	.card-content {
		width: 100%;
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.rating-stars {
		margin: 5px 0;
	}
}
