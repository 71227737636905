@import "../../assets/styles/config.scss";

.button_facebook {
	background: #3b5998 !important;
	color: #fff;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.link {
	color: $cs-secondary-green;
}

.button-row {
	display: flex;
	align-items: center;
	.button {
		margin-right: 10px;
	}
}

.buttons-no-margin {
	margin-top: 0 !important;
}

.buttons-stacked {
	flex-direction: column;
	.button {
		width: 100%;
		margin: 0 0 10px 0 !important;
	}
}

.buttons-wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.button {
		margin: 0 15px 15px 0 !important;
	}
}
