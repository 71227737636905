#getcrewdone,
[data-theme="getcrewdone"] {
	--primary-color: #f88c5e;
	--primary-color-900: #f88c5e90;
	--primary-color-800: #f88c5e80;
	--primary-color-700: #f88c5e70;
	--primary-color-600: #f88c5e60;
	--primary-color-500: #f88c5e50;
	--primary-color-400: #f88c5e40;
	--primary-color-300: #f88c5e30;
	--primary-color-200: #f88c5e20;
	--primary-color-100: #f88c5e10;

	--dark-color: #000000;
	--dark-color-900: #00000090;
	--dark-color-800: #00000080;
	--dark-color-700: #00000070;
	--dark-color-600: #00000060;
	--dark-color-500: #00000050;
	--dark-color-400: #00000040;
	--dark-color-300: #00000030;
	--dark-color-200: #00000020;
	--dark-color-100: #00000010;
	--dark-color-50: #00000005;

	--background: #ffffff;
	--background-foreground: #000000;

	--gray-background: #f4f6f8;

	--card: #ffffff;
	--card-foreground: #000000;

	// --accent: #f9fafb;
	// --accent: #f4f4f4;
	--accent: #f3f3f4;
	--accent-foreground: #000000;

	// --border: #eaecf0;
	--border: #e2e5ec;
	// --border: #dce0e9;
	--border-foreground: #000000;

	--placeholder: #00000035;

	--primary: #f88c5e;
	--primary-foreground: #ffffff;

	--shadow-default: 0 1px 15px 0 rgba(0, 0, 0, 0.05),
		0 1px 2px 0 rgba(0, 0, 0, 0.06);

	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	.fill-primary {
		fill: #f88c5e !important;
	}

	.fill-dark {
		fill: var(--dark-color) !important;
	}

	.fill-white {
		fill: #fff !important;
	}
}

#getcrewdone.dark {
	--background: #191919;
	--background-foreground: #ffffff;

	--card: #252525;
	--card-foreground: #ffffff;

	--accent: #252525;
	--accent-foreground: #ffffff;

	// --border: #343434;
	--border: #3f3f3f;
	--border-foreground: #ffffff;

	--placeholder: #ffffff35;

	--primary: #f88c5e;
	--primary-foreground: #ffffff;

	--shadow-default: 0px 4px 4px 0px rgba(0, 0, 0, 0.18);
	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
