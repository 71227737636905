//Light mode & default variables
#odylyt {
	--primary-color: #228fd4;
	--primary-color-900: #228fd490;
	--primary-color-800: #228fd480;
	--primary-color-700: #228fd470;
	--primary-color-600: #228fd460;
	--primary-color-500: #228fd450;
	--primary-color-400: #228fd440;
	--primary-color-300: #228fd430;
	--primary-color-200: #228fd420;
	--primary-color-100: #228fd410;

	--dark-color: #121212;
	--dark-color-900: #12121290;
	--dark-color-800: #12121280;
	--dark-color-700: #12121270;
	--dark-color-600: #12121260;
	--dark-color-500: #12121250;
	--dark-color-400: #12121240;
	--dark-color-300: #12121230;
	--dark-color-200: #12121220;
	--dark-color-100: #12121210;
	--dark-color-50: #12121205;

	.fill-primary {
		fill: var(--primary-color) !important;
	}

	.fill-dark {
		fill: var(--dark-color) !important;
	}

	.fill-white {
		fill: #fff !important;
	}

	--background: #ffffff;
	--background-foreground: #27272a;

	--card: #ffffff;
	--card-foreground: #27272a;

	--gray-background: #f3f3f3;

	--accent: #e7e7e7;
	--accent-foreground: #27272a;

	--border: #e0e0e0;
	--border-foreground: #27272a;

	--placeholder: #27272a35;

	--primary: #3971ff;
	--primary-foreground: #ffffff;

	// --shadow-default: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
	--shadow-default: 0px 3px 16px -1px rgba(50, 50, 71, 0.07);

	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	h1,
	h2,
	h3 {
		font-family: "Nunito", sans-serif;
	}
}

//Dark mode
#odylyt.dark {
	--background: #121212;
	--background-foreground: #f5f5f7;

	--card: #1e1e1e;
	--card-foreground: #f5f5f7;

	--accent: #222;
	--accent-foreground: #f5f5f7;

	// --border: #343434;
	--border: #333;
	--border-foreground: #f5f5f7;

	--placeholder: #f5f5f735;

	--primary: #3971ff;
	--primary-foreground: #ffffff;

	--shadow-default: 0px 4px 4px 0px rgba(0, 0, 0, 0.18);
	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
