:root {
	--scrollbar-size: 10px;
}

.light {
	--scrollbar-track-color: rgba(0, 0, 0, 0.3);
	--scrollbar-track-background: rgba(0, 0, 0, 0.02);
	--scrollbar-thumb-color: rgba(0, 0, 0, 0.3);
	--scrollbar-thumb-border: rgba(0, 0, 0, 0);
	body::-webkit-scrollbar-track {
		background: var(--accent);
	}
}

.dark {
	--scrollbar-track-color: rgba(255, 255, 255, 0.1);
	--scrollbar-track-background: transparent;
	--scrollbar-thumb-color: rgba(255, 255, 255, 0.05);
	--scrollbar-thumb-border: rgba(255, 255, 255, 0);

	body::-webkit-scrollbar-track {
		background: var(--background);
	}
}

::-webkit-scrollbar {
	width: var(--scrollbar-size);
	height: var(--scrollbar-size);
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb-color);
	border-radius: 10px;
	border: 2px solid var(--scrollbar-thumb-border);
	background-clip: padding-box;
	border-radius: 9999px;
}

::-webkit-scrollbar-track {
	background: var(--scrollbar-track-background);
	border-radius: 10px;
}
