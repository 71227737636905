@import "../../../assets/styles/config.scss";

.resume-header {
	position: relative;
	overflow: hidden;
	.avatar-wrapper {
		position: relative;
		z-index: 88;
		.icon-button {
			top: 0;
			right: -10px;
		}
	}
	.icon-button {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	&-row {
		display: flex;
		align-items: center;
		@include for-phone-only {
			flex-direction: column;
		}
	}
	&-info {
		margin-left: 30px;
		@include for-phone-only {
			margin: 15px 0 0 0;
		}
		h2 {
			@include for-phone-only {
				text-align: center;
			}
		}
		p {
			opacity: 0.8;
			margin: 15px 0 0 0 !important;
			text-align: left;
			white-space: pre-wrap;

			@include for-phone-only {
				text-align: center;
			}
		}
	}

	.big-g {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		overflow: hidden;
		@include for-phone-only {
			top: auto;
			bottom: 0;
			right: 0;
		}
		svg {
			transform: translate(40px, -40px);
			width: 300px;
			height: 300px;
			opacity: 0.4;

			@include for-phone-only {
				width: 300px;
				transform: translate(40px, 40px);
			}
		}
	}
}
