@import "../../assets/styles/config.scss";

.card {
	&_to {
		text-decoration: none;
	}
	&_center {
		@include full-center();
	}

	&_small-padding {
		.card-content {
			padding: 24px;
		}
	}
	.FlatList-root-1 {
		margin-top: 0 !important;
	}
	&_no-padding {
		.card-content {
			padding: 0;
		}
	}

	.close-button {
		position: absolute;
		right: 0;
		cursor: pointer;
	}

	&-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.setting-item {
		margin-bottom: 20px;
	}
}

.card.full-width {
	width: 100%;
}
