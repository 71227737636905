@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import "./config.scss";

body {
	font-family: "Inter", sans-serif;
	font-weight: 300;
	font-size: 18px;
	font-display: swap;
}

html.dark {
	color: #fff;
}

html.light {
	color: #18383b;
}

.text-orange {
	background: -webkit-linear-gradient(135deg, #f88c5e, #f9b739);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-wrap-balance {
	text-wrap: balance;
}

.leading-full {
	line-height: 100%;
}

.font-regular {
	font-weight: 400 !important;
	// line-height: 22px;
}

.leading-full {
	line-height: 100%;
}

h1,
h2,
h3,
h4 {
	font-weight: 700;
}

.text-right {
	text-align: right !important;
}

h1 {
	font-size: 48px;
	font-display: swap;
	line-height: 120%;
	@include for-phone-only {
		font-size: 32px;
		word-break: break-word;
	}
	.green {
		color: $cs-secondary-green !important;
	}
}

h1.subtitle {
	font-size: 30px;
	font-display: swap;
	@include for-phone-only {
		font-size: 22px;
		word-break: break-word;
	}
}

h2 {
	font-size: 30px;
	line-height: 120%;
	font-display: swap;
	@include for-phone-only {
		font-size: 24px;
		word-break: break-word;
	}
}

h3 {
	font-size: 24px;
	line-height: 24px;
}

.highlight-green {
	color: $cs-secondary-green;
	font-display: swap;
	text-decoration: none;
}

.color-green {
	color: $cs-secondary-green;
}

.highlight-red {
	color: $color-error;
}

.inline {
	display: inline !important;
}

strong,
b,
.font-bold {
	font-weight: 700;
	font-display: swap;
}

.container-small {
	h2 {
		margin-bottom: 15px;
	}
	p {
		margin-bottom: 30px;
		line-height: 24px;
	}
}

.text-error {
	color: $color-error;
}

.input-editable-title {
	resize: none;
	// &::placeholder {
	// 	color: #18383b !important;
	// 	opacity: 0.5;
	// }
}

.input-editable-title_medium {
	font-size: 24px !important;
	line-height: 120% !important;
	font-weight: 700;
	&::placeholder {
		font-weight: 700;
	}
}

.input-editable-title_large {
	font-size: 38px;
	font-display: swap;
	line-height: 140%;
	font-weight: 700;

	@include for-phone-only {
		font-size: 28px;
		word-break: break-word;
	}
	&::placeholder {
		font-weight: 700;
	}
}
