@import "../../../assets/styles/config.scss";

.payment-listener {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	.payment-notification {
		padding: 15px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		box-shadow: 0px 3px 66px #16173029;
		background: #fff;
		transition: 0.4s ease all;
		&-success {
			background-color: $color-valid;
			box-shadow: 0px 3px 66px darken($color-valid, 10);
			color: #fff;

			p {
				color: #fff !important;
			}
		}
		.icon {
			margin-right: 10px;
		}
		p {
			margin: 0;
			color: #22384b;
		}
	}
}
