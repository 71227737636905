$cs-base: #22384b;
$cs-primary: #fff;
$cs-dark-blue: #161730;
$cs-lila: #747c9a;
$cs-secondary-orange: linear-gradient(135deg, #f88c5e 0%, #f9b739 100%);
$cs-secondary-green: #1aaa9d;
$cs-secondary-gray: #c4d7d1;

$cc-base: #f3f3f3;
$cc-primary: #fff;
$cc-dark-blue: #161730;
$cc-orange: linear-gradient(135deg, #f88c5e 0%, #f9b739 100%);
$cc-secondary-lila: rgba(116, 124, 154, 1);
$cc-secondary-green: #1aaa9d;
$cc-secondary-gray: #c4d7d1;

$color-text-background-foreground: #161730;
$color-gray: #f5f5f5;
$color-error: #ef4444;
$color-dark-blue: #161730;
$color-purple: #22384b;
$color-valid: #1aaa9d;
$color-lila: rgba(116, 124, 154, 1);
$color-orange-gradient: linear-gradient(135deg, #f88c5e 0%, #f9b739 100%);
$color-orange: linear-gradient(135deg, #f88c5e 0%, #f9b739 100%);

$color-targets-background: #2a343d;
$color-targets-text: #fff;

$nav-height: 100px;
$nav-height-small: 80px;
$button-height: 50px;

$portal-nav-width: 50px;

$box-shadow: 0px 3px 66px #16173029;

$sub-height: 44px;
$sub-mobile-height: 16px;

.bg-orange-gradient {
	background: $color-orange-gradient;
}

@mixin text-gradient($gradient) {
	background-image: $gradient;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

@mixin full-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@mixin full-height {
	display: flex;
	flex-direction: column;
	flex: 1;
}

@mixin circle($size) {
	width: $size;
	height: $size;
	border-radius: calc(#{$size} / 2);
}

@mixin for-phone-only {
	@media (max-width: 599px) {
		@content;
	}
}

@mixin for-tablet-under {
	@media (max-width: 1080px) {
		@content;
	}
}

@mixin for-tablet-up {
	@media (min-width: 800px) {
		@content;
	}
}

@mixin for-tablet-portrait-up {
	@media (min-width: 600px) {
		@content;
	}
}
@mixin for-tablet-landscape-up {
	@media (min-width: 900px) {
		@content;
	}
}
@mixin for-desktop-up {
	@media (min-width: 1200px) {
		@content;
	}
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) {
		@content;
	}
}

@mixin use-color-styles {
	&_danger {
		color: #fff;
		background: $color-error !important;
	}
	&_base {
		background: $cs-base !important;
		color: #fff;
	}
	&_outlined {
		border: 2px solid $cs-base !important;
		color: $cs-base;
		background: #fff;
	}
	&_outlined-white {
		border: 2px solid #fff !important;
		color: #fff;
		background: transparent;
	}
	&_outlined-primary {
		border: 2px solid $cs-secondary-green !important;
		color: $cs-secondary-green;
		background: transparent;
	}

	&_orange {
		background: $color-orange-gradient !important;
		color: #fff;
	}
	&_green {
		background: $cs-secondary-green !important;
		color: #fff;
	}
	&_white {
		background: #fff;
		color: $cs-dark-blue !important;
	}

	&_link {
		background: transparent;
		color: $cs-secondary-green !important;
	}

	&_purple {
		background: #757c9b !important;
	}

	&_gray {
		background: $color-gray !important;
		color: $cs-base;
	}
}

.border-orange-gradient {
	position: relative;
	z-index: 4;
	&::before {
		z-index: -1;
		content: "";
		display: block;
		position: absolute;
		top: -2px;
		bottom: -2px;
		left: -2px;
		right: -2px;
		background: $color-orange-gradient !important;
		border-radius: 6px;
	}
}

.border-orange-gradient-square {
	position: relative;
	z-index: 4;
	&::before {
		z-index: -1;
		content: "";
		display: block;
		position: absolute;
		top: -2px;
		bottom: -2px;
		left: -2px;
		right: -2px;
		background: $color-orange-gradient !important;
		border-radius: 0px;
	}
}
