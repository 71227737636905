@import "../../assets/styles/config.scss";

.layout {
	padding-top: 20px !important;

	@include for-phone-only {
		padding-top: 20px !important;
	}

	.container {
		max-width: 1400px;
	}
	.text-center {
		@include for-tablet-up {
			text-align: center !important;
		}
	}

	.text-center-mobile {
		@include for-phone-only {
			text-align: center !important;
		}
	}

	.cta-create-button {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 88;

		a {
			text-decoration: none;
		}

		&-label {
			$textcontainer-height: 34px;
			height: $textcontainer-height;
			background: linear-gradient(270deg, #f88c5e 25%, #f9b739 100%);
			position: absolute;
			right: 4px;
			top: 50%;
			transform: translateY(-50%);
			margin-left: -2rem;
			border-radius: 999px;
			z-index: -1;
			overflow: hidden;
			white-space: nowrap;

			& > div {
				position: relative;
				height: 100%;
				width: 100%;

				span {
					width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-left: 1rem;
					color: white;
					font-weight: 700;
				}
			}
		}

		&-inner {
			@include circle(55px);
			background: linear-gradient(195deg, #f88c5e 0%, #f9b739 100%);
			display: flex;
			justify-content: center;
			align-items: center;

			.label {
				display: none;
			}

			i {
				color: white;
				font-size: 1.2em;
			}
		}
	}

	&-credit {
		white-space: nowrap;
		@include for-phone-only {
			flex: 1;
			margin-right: 10px;
			display: flex;
			justify-content: flex-end;
		}
		a {
			text-decoration: none;
			color: #fff;
			border: 1px solid #fff;
			padding: 10px 15px;
			border-radius: 30px;
			background: rgba(255, 255, 255, 0.1);
		}
		p {
			margin: 0;
			@include for-phone-only {
				text-align: right !important;
				padding-right: 10px;
			}
		}
	}

	.hide-mobile {
		@include for-phone-only {
			display: none;
		}
	}

	&-loading {
		@include full-center();
		@include full-height();
	}

	.header-content {
		width: 100% !important;
		max-width: 100% !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
	&-head {
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@include for-phone-only {
			margin-bottom: 0px;
		}
		h2 {
			@include for-phone-only {
				display: none;
			}
		}
		&-burger {
			display: none;
			@include for-phone-only {
				display: block;
			}
		}
	}

	&-wrapper {
		width: 100%;
	}
	&-grid {
		width: 100%;
		flex: 1;
		grid-template-rows: 100%;
		@include for-phone-only {
			grid-gap: 0;
			display: flex !important;
			flex-direction: column;
			margin-top: 20px;
		}
	}
	&-content {
		width: 100%;
		flex: 1;
		max-width: 100%;
		@include for-phone-only {
			width: 100%;
		}
		.container-small {
			width: 100% !important;
			@include for-phone-only {
				width: 100% !important;
			}
		}
		&-loading,
		&-empty {
			@include full-center();
			@include full-height();
		}
		> .container,
		.container-small,
		.container-xsmall,
		.container-lg {
			width: 100%;
			padding: 0;
		}

		> .container-grid,
		.container-grid-small,
		.container-grid-xsmall,
		.container-grid-lg {
			width: 100%;
			padding: 0;
		}
	}
	&-footer {
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		opacity: 1;
		font-size: 12px;
		padding-top: 60px;
		width: 100%;
		opacity: 0.4;
		@include for-tablet-under {
			display: none;
		}
		p,
		a {
			margin: 0;
			text-decoration: none;
		}
		.links {
			a {
				color: #fff;
			}
			.divider {
				width: 26px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

.admin-mobile-nav_open .layout-credit {
	@include for-phone-only {
		display: none;
	}
}
