@import "../../../../assets/styles/config.scss";

.download-app-box {
	display: flex;
	@include for-phone-only {
		flex-direction: column;
		a {
			margin: 0 0 15px 0;
		}
	}
	&_small {
		img {
			height: 40px;
		}
	}

	&_medium {
		img {
			height: 50px;
		}
	}

	&_large {
		img {
			height: 70px;
		}
	}
	a {
		margin-right: 15px;
	}
	img {
		display: block;
		object-fit: contain;
		margin: 0 !important;
	}
}
