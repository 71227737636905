@import "../../assets/styles/config.scss";

.flat-list {
	&-horizontal {
		@include for-phone-only {
			margin: -20% -5vw;
			padding: 20% 10vw;
			overflow-x: auto;
			.flat-list-content {
				display: flex;

				.task {
					&-content {
						width: 80vw;
					}
				}
			}
		}
	}

	&-search {
		&_table {
			padding: 0 15px;
		}
		.input {
			margin: 0;
			label {
				display: none;
			}
			input {
				border-radius: 0;
				border-color: #fafafa;
			}
		}
	}

	&-loading {
		@include full-center();
		flex: 1;
		width: 100%;
	}
	&-empty {
		@include full-center();
		flex: 1;
		width: 100%;
		strong {
			margin-bottom: 10px;
		}
	}
	&-has-more {
		@include full-center();
		width: 100%;
		padding: 30px;
	}
	&-load-more {
		@include full-center();
		padding: 32px;
	}
}

.MuiPaper-rounded {
	box-shadow: none !important;
}
