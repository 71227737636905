.money-counter {
	strong {
		margin: 0;
	}
	.price {
		margin: 5px 0 0 0;
		font-size: 32px;
	}
	.base-loader {
		margin-top: 15px;
	}
}
