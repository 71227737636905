@import "../../../assets/styles/config.scss";

.date-of-birth-picker {
	width: 100%;
	margin-bottom: 15px;
	label {
		margin-bottom: 5px;
	}
	&_has-error {
		label {
			color: $color-error;
		}
	}
	&_has-valid {
		label {
			color: $color-valid;
		}
	}
	&-row {
		display: flex;
		width: 100%;
		align-items: center;
		@include for-phone-only {
			flex-direction: column;
			align-items: flex-start;
		}
		.dropdown {
			margin-right: 5px;
			flex: 1;
			@include for-phone-only {
				width: 100%;
				margin: 0 0 5px 0;
			}
		}
	}
	&-error {
		margin: 5px 0 0 !important;
		font-size: 14px;
		transition: 0.4s ease all;
		color: $color-error;
	}
}
