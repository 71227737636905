@import "../../../assets/styles/config.scss";

.address-row {
	display: flex;
	@include for-phone-only {
		flex-wrap: wrap;
		.input {
			margin-right: 0 !important;
			&:first-child {
				min-width: 0 !important;
			}
		}
	}
	.input {
		margin-right: 15px;
		&:first-child {
			min-width: 300px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
