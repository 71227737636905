// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.mb-0 {
	margin-bottom: 0 !important;
}

@layer base {
	.bg-orange-gradient {
		@apply from-dark-orange to-orange bg-gradient-to-l;
	}
}
