@import "../../../assets/styles/config.scss";

$borderWidth: 1.5px;
$inputHeight: 43px;

// html.dark .input {
// 	input,
// 	textarea {
// 		background-color: #18383b;
// 		color: #fff;
// 		&::placeholder {
// 			color: #fff;
// 		}
// 	}
// }

.border-input {
	border: $borderWidth solid darken($color-gray, 10);
}

// .input_disabled {
// 	input,
// 	textarea {
// 		color: darken($color-gray, 30) !important;
// 		border: $borderWidth solid darken($color-gray, 10);
// 	}
// }

.react-colorful__saturation {
	border-radius: 6px 6px 0 0 !important;
}

.react-colorful__last-control {
	border-radius: 0 0 6px 6px !important;
}

.placeholder-placeholder::placeholder {
	color: #18383b;
	opacity: 0.5;
}

.input-has-prefix {
	input {
		border-left: 0px !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		padding-left: 16px !important;
	}
}

.input {
	width: 100%;
	&-has-auto-fill {
		position: relative;
	}

	// &_dark {
	// 	input,
	// 	textarea {
	// 		background-color: $color-purple !important;
	// 		color: #fff !important;
	// 		&::placeholder {
	// 			color: #fff !important;
	// 		}
	// 	}
	// }
	// &_transparent {
	// 	input,
	// 	textarea {
	// 		background-color: transparent !important;
	// 		color: #fff !important;

	// 		&::placeholder {
	// 			color: #fff !important;
	// 		}
	// 	}
	// }
	// &_transparent-dark {
	// 	input,
	// 	textarea {
	// 		background-color: transparent !important;
	// 		color: #161730 !important;
	// 		&::placeholder {
	// 			color: #161730 !important;
	// 		}
	// 	}
	// }
	&_telephone,
	&_price {
		.input-wrapper {
			display: flex;
		}
		input {
			border-radius: 0 6px 6px 0 !important;
		}
	}

	// .icon-prefix,
	// .price-prefix,
	// &-increment {
	// 	color: darken($color-gray, 30);
	// 	border: $borderWidth solid darken($color-gray, 10);
	// }
	.icon-prefix,
	.price-prefix {
		// background: $color-gray;
		border-radius: 6px 0 0 6px;
		// border: $borderWidth solid darken($color-gray, 10);
		border-right: 0;
		min-width: $inputHeight !important;
		height: $inputHeight;
		@include full-center();
		p {
			// color: #18383b;
			opacity: 0.5;
			margin: 0;
		}
	}
	&-increment {
		// background: $color-gray;
		padding: 12px 15px;
		min-width: $inputHeight;
		cursor: pointer;
		@include full-center();
		transition: 0.2s ease all;
		// &:hover {
		// 	background: rgba(33, 55, 74, 0.25);
		// }
		&:first-child {
			border-right: 0;

			border-radius: 6px 0 0 6px;
		}
		&:last-child {
			border-left: 0;
			border-radius: 0 6px 6px 0;
		}
	}
	&_increments {
		.input-wrapper {
			display: flex;
		}
		input {
			border-radius: 0 0 0 0 !important;
		}
	}

	&_increments-right {
		.input-wrapper {
			display: flex;
		}
		input {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.price-prefix {
		width: $inputHeight;
	}

	&_has-error {
		color: $color-error;
		.icon-prefix,
		.price-prefix,
		&-increment {
			border-color: $color-error !important;
		}
		input {
			border-color: $color-error !important;
		}
	}

	&-wrapper {
		position: relative;
	}

	&-row {
		display: flex;
		input {
			border-right-width: 0 !important;
			border-radius: 6px 0 0 6px !important;
		}
		.button {
			border-radius: 0 6px 6px 0;
			padding: 0;
			height: $inputHeight;
			width: $inputHeight;
			border: $borderWidth solid rgba(33, 55, 74, 0.25);
		}
	}

	&-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		@include full-center();
		.loader {
			border: 2px solid rgba(0, 0, 0, 0.2);
			border-top: 2px solid $color-gray;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			animation: spin 2s linear infinite;
			margin-right: 30px;
		}
	}

	// textarea {
	// 	min-height: 120px;
	// }

	&-error {
		margin: 5px 0 0 !important;
		font-size: 14px;
		transition: 0.4s ease all;
	}
}

.input_date,
.input_date-time {
	width: 100%;

	.DayPicker_weekHeader {
		top: 84px;
	}
	input,
	.DateInput {
		border-radius: 6px;
		flex: 1;
	}
	input {
		border: 0px !important;
		border-radius: 6px;
	}
	td {
		border-color: rgba(33, 55, 74, 0.25);
	}
	table {
		border-color: rgba(33, 55, 74, 0.25);
	}
	.CalendarDay,
	.CalendarDay__outside {
		border-color: rgba(33, 55, 74, 0.25);
		opacity: 1;
	}
	.DayPickerNavigation_button__horizontalDefault {
		top: 34px;
	}
}

.month-element {
	h2 {
		font-size: 22px;
		margin: 0 !important;
	}
	select {
		border-color: rgba(33, 55, 74, 0.25);
		padding: 4px;
		border-radius: 6px;
		margin-top: 5px;
	}
}

.form-row {
	display: flex;
	.input {
		&:last-child {
			margin-right: 0;
		}
	}
}

.input input,
.input textarea {
	font-size: 16px !important;
	// color: #18383b;
	width: 100%;
	transition: 0.4s ease all;
	border-radius: 6px;
	font-size: 16px;
	padding: 8px 12px;
	width: 100%;
	transition: 0.4s ease all;
	// border: $borderWidth solid darken($color-gray, 10);
}

// input,
// textarea {
// 	&::placeholder {
// 		color: #18383b !important;
// 		opacity: 0.5;
// 	}
// }

.textarea-small {
	margin: 0 !important;
	textarea {
		height: 50px;
		min-height: 50px;
		@include for-phone-only {
			height: 100% !important;
		}
	}
}

.textarea-medium {
	margin: 0 !important;
	textarea {
		height: 80px;
		min-height: 50px;
		@include for-phone-only {
			height: 100% !important;
		}
	}
}

.DayPickerKeyboardShortcuts_buttonReset {
	display: none;
}

.DayPicker > * {
	border: 0px !important;
	box-shadow: none !important;
}

.DayPicker__withBorder {
	box-shadow: none !important;
}

//First line
.ProseMirror p.is-editor-empty:first-child::before {
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
	// color: #18383b50 !important;
}

.ProseMirror,
.description {
	> * + * {
		margin-top: 0.75em;
	}

	img {
		max-width: 700px;
		width: 95%;
		margin: 20px auto;
		display: block;
		border-radius: 4px;
		cursor: grab;

		&.ProseMirror-selectednode {
			border: 1px solid var(--dark-color);
			padding: 2px;
			cursor: grabbing;
		}
	}
}

.description {
	p:last-child,
	p:empty {
		margin: 0;
	}
}

//Every line
// .ProseMirror p.is-empty::before {
// 	color: #adb5bd;
// 	content: attr(data-placeholder);
// 	float: left;
// 	height: 0;
// 	pointer-events: none;
// }
