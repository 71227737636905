.formatted-task-description {
	p {
		white-space: pre-wrap;
		word-break: break-word;
	}

	ul,
	ol {
		padding-left: 30px;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}
}

.description p {
	white-space: pre-wrap;
	word-break: break-word;
}

.ProseMirror ul,
.description ul {
	list-style: disc;
	padding-left: 30px;
	margin: 10px 0;
}

.ProseMirror ol,
.description ol {
	list-style: decimal;
	padding-left: 30px;
	margin: 10px 0;
}

.ProseMirror p,
.description p {
	line-height: 1.48em;
}

.ProseMirror img,
.description-preview img {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
		0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.description-preview .img-cover {
	background: #f7f7f7;
	display: flex;
	padding: 24px 0 0 24px;
	border-radius: 8px;
	overflow: hidden;
	max-height: 400px !important;
}

.description-preview .img-cover img {
	margin: 0 !important;
	width: 100%;
	border-radius: 8px 0 0 0;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
		0 1px 2px -1px rgba(0, 0, 0, 0.1) !important;
	height: 100%;
}

.description-preview .color,
.tiptapp-editor-content .color {
	white-space: nowrap;

	&::before {
		background-color: var(--color);
		border: 1px solid rgba(128, 128, 128, 0.3);
		border-radius: 2px;
		content: " ";
		display: inline-block;
		height: 1em;
		margin-bottom: 0.15em;
		margin-right: 0.1em;
		vertical-align: middle;
		width: 1em;
	}
}

.tiptap-editor-content_hide-placeholder {
	p[data-placeholder] {
		&::before {
			opacity: 0 !important;
		}
	}
}

.description-preview ul[data-type="taskList"],
.description ul[data-type="taskList"] {
	list-style: disc;
	input[type="checkbox"] {
		pointer-events: none;
		display: none;
	}
}

.description-preview ul[data-type="taskList"],
.tiptapp-editor-content ul[data-type="taskList"],
.description ul[data-type="taskList"] {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin: 12px 0;

	li {
		display: flex;

		ul {
			margin: 4px 0;
		}

		> label {
			flex: 0 0 auto;
			margin-right: 0.5rem;
			user-select: none;
			display: flex;
			justify-content: center;
			height: 25px;
			align-items: center;
		}

		> div {
			flex: 1 1 auto;
		}
	}

	input[type="checkbox"] {
		appearance: none;
		display: grid;
		place-content: center;
		cursor: pointer;
		width: 16px;
		height: 16px;
		position: relative;
		border-radius: 2px;
		border: 1px solid var(--background-foreground);
		background-color: transparent !important;
	}

	input[type="checkbox"]::before {
		font-family: "Font Awesome 5 Pro";
		font-size: 8px;
		color: var(--background);
		content: "\f00c";
		position: absolute;
		inset: 1px;
		border-radius: 1px;
		background: var(--background-foreground);
		display: flex;
		justify-content: center;
		align-items: center;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
	}

	input[type="checkbox"]:checked::before {
		transform: scale(1);
	}
}

.description-preview,
.tiptapp-editor-content,
.description,
.ProseMirror {
	.mention {
		font-weight: 900;
		position: relative;
		z-index: 2;
		&::before {
			content: "";
			background: var(--primary-color-300);
			position: absolute;
			inset: -1.5px;
			border-radius: 4px;
			z-index: 1;
		}
	}

	a {
		text-decoration: underline;
		cursor: pointer;
		color: #2870d1;
	}
}
