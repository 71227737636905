#getstagedone,
[data-theme="getstagedone"] {
	--primary-color: #e24949;
	--primary-color-900: #e2494990;
	--primary-color-800: #e2494980;
	--primary-color-700: #e2494970;
	--primary-color-600: #e2494960;
	--primary-color-500: #e2494950;
	--primary-color-400: #e2494940;
	--primary-color-300: #e2494930;
	--primary-color-200: #e2494920;
	--primary-color-100: #e2494910;

	--dark-color: #282323;
	--dark-color-900: #28232390;
	--dark-color-800: #28232380;
	--dark-color-700: #28232370;
	--dark-color-600: #28232360;
	--dark-color-500: #28232350;
	--dark-color-400: #28232340;
	--dark-color-300: #28232330;
	--dark-color-200: #28232320;
	--dark-color-100: #28232310;
	--dark-color-50: #28232305;

	// --dark-color: #18181b;
	// --dark-color-900: #18181b90;
	// --dark-color-800: #18181b80;
	// --dark-color-700: #18181b70;
	// --dark-color-600: #18181b60;
	// --dark-color-500: #18181b50;
	// --dark-color-400: #18181b40;
	// --dark-color-300: #18181b30;
	// --dark-color-200: #18181b20;
	// --dark-color-100: #18181b10;
	// --dark-color-50: #18181b05;

	--dark-accent-color: #213f41;

	/* Brand Colors */
	--aqua-dark: 175, 73%, 38%;
	--aqua-light: 176, 66%, 84%;

	--purple-dark: 250, 22%, 32%;
	--purple-light: 249, 100%, 82%;

	--orange-dark: 24, 91%, 58%;
	--orange-light: 23, 100%, 89%;

	--green-dark: 112, 89%, 14%;
	--green-light: 97, 71%, 67%;

	:root {
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
	}

	.fill-primary {
		fill: var(--primary-color) !important;
	}

	.fill-dark {
		fill: var(--dark-color) !important;
	}

	.fill-white {
		fill: #fff6f6 !important;
	}

	--background: #fff6f6;
	--foreground: #282323;
	--background-foreground: #282323;

	--card: #fff6f6;
	--card-foreground: #282323;

	--accent: #f5f5f5;
	--accent-soft: #f2f2f2;
	--accent-foreground: #282323;

	--border: #e2e5ec;
	--border-foreground: #282323;

	--placeholder: #28232335;

	--primary: #e24949;
	--primary-foreground: #fff6f6;

	--gray-background: #f3f3f3;

	--shadow-default: 0 1px 15px 0 rgba(0, 0, 0, 0.05),
		0 1px 2px 0 rgba(0, 0, 0, 0.06);

	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

#getstagedone.dark,
.theme-dark {
	--background: #191919;
	--background-foreground: #fff6f6;
	--foreground: #fff6f6;

	--card: #252525;
	--card-foreground: #fff6f6;

	--accent: #252525;
	--accent-foreground: #fff6f6;

	--border: #3f3f3f;
	--border-foreground: #fff6f6;

	--placeholder: #fff6f635;

	--primary: #e24949;
	--primary-foreground: #fff6f6;

	--shadow-default: 0px 4px 4px 0px rgba(0, 0, 0, 0.18);
	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	--chart-1: 220 70% 50%;
	--chart-2: 160 60% 45%;
	--chart-3: 30 80% 55%;
	--chart-4: 280 65% 60%;
	--chart-5: 340 75% 55%;
}
