@import "../../assets/styles/config.scss";

.grid:not(.ignore-template) {
	display: grid;
	@include for-phone-only {
		grid-template-columns: repeat(1, 1fr) !important;
		.card {
			margin-bottom: 0px;
		}
	}
	.span-2-columns {
		grid-column: 1 / span 2;
		@include for-phone-only {
			grid-column: 1;
		}
	}
}
