#getrecruitmentdone,
[data-theme="getrecruitmentdone"] {
	--primary-color: #337ceb;
	--primary-color-900: #337ceb90;
	--primary-color-800: #337ceb80;
	--primary-color-700: #337ceb70;
	--primary-color-600: #337ceb60;
	--primary-color-500: #337ceb50;
	--primary-color-400: #337ceb40;
	--primary-color-300: #337ceb30;
	--primary-color-200: #337ceb20;
	--primary-color-100: #337ceb10;

	--dark-color: #172a54;
	--dark-color-900: #172a5490;
	--dark-color-800: #172a5480;
	--dark-color-700: #172a5470;
	--dark-color-600: #172a5460;
	--dark-color-500: #172a5450;
	--dark-color-400: #172a5440;
	--dark-color-300: #172a5430;
	--dark-color-200: #172a5420;
	--dark-color-100: #172a5410;
	--dark-color-50: #172a5405;

	// --dark-color: #18181b;
	// --dark-color-900: #18181b90;
	// --dark-color-800: #18181b80;
	// --dark-color-700: #18181b70;
	// --dark-color-600: #18181b60;
	// --dark-color-500: #18181b50;
	// --dark-color-400: #18181b40;
	// --dark-color-300: #18181b30;
	// --dark-color-200: #18181b20;
	// --dark-color-100: #18181b10;
	// --dark-color-50: #18181b05;

	--dark-accent-color: #213f41;

	:root {
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
	}

	.fill-primary {
		fill: var(--primary-color) !important;
	}

	.fill-dark {
		fill: var(--dark-color) !important;
	}

	.fill-white {
		fill: #fff !important;
	}

	--gray-background: #f8fbff;

	--background: #ffffff;
	--foreground: #172a54;
	--background-foreground: #172a54;

	--card: #fdfeff;
	--card-foreground: #172a54;

	--accent: #f0f6fb;
	--accent-soft: #eff7ff;
	--accent-foreground: #172a54;

	--border: #eaf1f8;
	--border-foreground: #172a54;

	--placeholder: #172a5435;

	--primary: var(--primary-color);
	--primary-foreground: #ffffff;

	--shadow-default: 0 1px 15px 0 rgba(0, 0, 0, 0.05),
		0 1px 2px 0 rgba(0, 0, 0, 0.06);

	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

#getrecruitmentdone.dark,
.theme-dark {
	--background: #191919;
	--background-foreground: #ffffff;
	--foreground: #ffffff;

	--card: #252525;
	--card-foreground: #ffffff;

	--accent: #252525;
	--accent-foreground: #ffffff;

	--border: #3f3f3f;
	--border-foreground: #ffffff;

	--placeholder: #ffffff35;

	--primary: #1aaa9d;
	--primary-foreground: #ffffff;

	--shadow-default: 0px 4px 4px 0px rgba(0, 0, 0, 0.18);
	--shadow-medium: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);
	--shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
		0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	--chart-1: 220 70% 50%;
	--chart-2: 160 60% 45%;
	--chart-3: 30 80% 55%;
	--chart-4: 280 65% 60%;
	--chart-5: 340 75% 55%;
}
