// @import "./themes/getcrewdone.scss";
// @import "./themes/getjobsdone.scss";
// @import "./themes/odylyt.scss";
@import "./config.scss";

* {
	min-width: 0;
}

:root {
	overscroll-behavior: none;
}

[data-radix-popper-content-wrapper] {
	z-index: 9999 !important;
}

#modal-root {
	z-index: 910;
}

#alert-root {
	z-index: 999;
}

.text-balance {
	text-wrap: balance;
}

body {
	overscroll-behavior: none;
}

pre {
	padding: 10vw;
	background: #ffffff;
	border: 2px solid #f2f2f2;
	border-radius: 8px;
}

.textarea-full {
	textarea {
		flex: 1;
		height: 100%;
	}
	.input-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		flex: 1;
	}
}

// table tr {
// 	border: 2px solid red;
// }
// table tbody tr,
// table tfoot tr,
// table thead tr {
// 	border: 0px !important;
// }

.bg-striped {
	background: repeating-linear-gradient(
		45deg,
		#fff 0px,
		#fff 20px,
		#f9fafb 20px,
		#f9fafb 40px
	);
}

.desktop-draggable {
	-webkit-app-region: drag;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

.focusable:focus {
	outline: 1px solid var(--dark-color);
}

.aspect-video {
	aspect-ratio: 16 / 9;
}

pre {
	background: #f4f4f460;
	border: 2px solid #f4f4f4;
	padding: 24px;
	border-radius: 8px;
}

.hide-date-icon::-webkit-inner-spin-button,
.hide-date-icon::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.aspect-square {
	aspect-ratio: 1 / 1;
}

html {
	scroll-behavior: smooth;
	min-height: 100dvh;
}

.infinite-scroll-component {
	overflow: inherit !important;
}

.react-colorful {
	width: 100% !important;
}

.orange-gradient {
	background: $color-orange-gradient;
}

.flip-horizontal {
	transform: scaleX(-1);
}

.cursor-pointer {
	cursor: pointer;
}

.shadow-top {
	box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.remove-m {
	margin: 0 !important;
}

main {
	@include full-height();
}

html,
body,
#root {
	@include full-height();
}

.full-center {
	@include full-height();
	@include full-center();
}

.form-group {
	margin-bottom: 30px;
}

.row {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.container {
	max-width: 1140px;
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	&-small {
		width: 90%;
		margin: 0 auto;
		max-width: 720px;
		display: flex;
		flex-direction: column;
	}
	&-xsmall {
		width: 90%;
		margin: 0 auto;
		max-width: 567px;
		display: flex;
		flex-direction: column;
	}
	&-lg {
		width: 90%;
		margin: 0 auto;
		max-width: 1540px;
		display: flex;
		flex-direction: column;
	}
}

.container,
.container-small,
.container-xsmall,
.container-lg {
	> .container,
	.container-small,
	.container-xsmall,
	.container-lg {
		width: 100%;
		padding: 0;
	}

	> .container-grid,
	.container-grid-small,
	.container-grid-xsmall,
	.container-grid-lg {
		width: 100%;
		padding: 0;
	}
}

.container-grid {
	max-width: 1140px;
	width: 90%;
	display: grid;
	margin: 0 auto;
	&-small {
		width: 90%;
		display: grid;
		margin: 0 auto;
		max-width: 720px;
	}
	&-xsmall {
		width: 90%;
		display: grid;
		margin: 0 auto;
		max-width: 567px;
	}
	&-lg {
		width: 90%;
		display: grid;
		margin: 0 auto;
		max-width: 1540px;
	}
}

.container-half {
	max-width: calc(1140px / 2);
	width: 90%;
	display: block;
	margin: 0 0 0 auto;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include for-phone-only {
		flex-direction: column;
		width: 100%;
	}
	&-center {
		justify-content: center !important;
	}
	.button {
		margin-right: 20px;
		@include for-phone-only {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}

.base-loader {
	border: 2px solid rgba(0, 0, 0, 0.2);
	border-top: 2px solid $color-gray;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	display: block;
	animation: spin 2s linear infinite;
	display: block;
}

.full-center {
	@include full-center();
}

.row-justify {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	.back-button,
	.buttons {
		margin: 0;
	}
}

table,
th,
td {
	border-collapse: collapse;
}

.disclaimer {
	background: $color-valid;
	color: #fff;
	p {
		color: #fff;
	}
}

.table-row-higlight {
	background-color: lighten($color-error, 50);
}

.span-1-2 {
	grid-column: 1 / span 2;
	@include for-phone-only {
		grid-column: 1;
	}
}

.day-picker {
	.nav-button {
		position: absolute;
		top: 22px;
		line-height: 0.78;
		border-radius: 3px;
		// padding: 6px 9px;
	}

	.nav-button-left {
		left: 15px;
	}

	.nav-button-right {
		right: 15px;
	}

	.DayPicker_weekHeader_li {
		// background: red;
		opacity: 0.4;
	}

	td {
		border: 0px;
	}

	.CalendarDay {
		position: relative !important;
		z-index: 5;
		background-color: transparent;

		&::after {
			z-index: -1;
			border-radius: 4px;
			content: "";
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			// transition: 0.2s ease background;
			background: transparent;
		}
	}

	.CalendarDay__outside {
		opacity: 0.4;
	}

	.CalendarDay__today {
		color: var(--dark-color);
		&::after {
			background: #f7f7f7;
		}
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover {
		border: 0 !important;
		color: #fff !important;
		&::after {
			background: var(--dark-color) !important;
		}
	}

	.CalendarDay:hover {
		border: 0 !important;
		color: var(--dark-color);
		&::after {
			background: #f7f7f7;
		}
	}

	// .CalendarMonth_caption {
	// 	padding-top: 0;
	// }
	// .DayPickerNavigation_leftButton__horizontalDefault {
	// 	left: 0;
	// }
	// .DayPickerNavigation_rightButton__horizontalDefault {
	// 	right: 0;
	// }
}

.day-is-today:not(.is-selected) {
	background: var(--border);
}

.is-invisible-day {
	opacity: 0 !important;
	cursor: inherit !important;
}
.day-range-middle {
	border-radius: 0;
}
